.boxPortfolio{
    width: 290px;
    height:200px;
     text-align: center;
    color: #fff;
    position: relative;
    overflow: hidden;

}

.boxPortfolio img{
  width: 100%;
  height: auto;

}

.childPortfolio,
.boxPortfolio:after{
    width: 100%;
    position: absolute;
    left: 0;
}

.boxPortfolio::after{
  content:'';
  height: 100%;
  top:0;
  transition: .5s;

}

.boxPortfolio:hover::after{
background: linear-gradient(to bottom,rgba(199,0,57,.01)0,rgba(199,0,57,.25)25%,rgba(199,0,57,.50)50%,rgba(199,0,57,.75)75%,rgba(199,0,57,.95)100%);

}

.childPortfolio{
    padding: 20px;
    margin-bottom: 20px;
    bottom: 0;
    z-index: 1;
    transform: translateY(145px);
    opacity: 0;
    transition: all .5s cubic-bezier(.13,.62,.81,.91)0s;

}

.boxPortfolio:hover .childPortfolio{
    transform: translateY(0);
    opacity: 1;
}