.text{
    background: linear-gradient(90deg,#00dbde,#fc00ff);
    font-size: 55px;
    font-weight: 800;
    margin-left: 20px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: mymove 9s infinite linear;
    position: relative;
}

@keyframes mymove {
    0%   {
    background: linear-gradient(90deg,#00dbde,#fc00ff);
    font-size: 55px;
    font-weight: 700;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    }
    
    50%{
    background: linear-gradient(90deg,#00dbde,#7bed9f);
    font-size: 55px;
    font-weight: 700;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    }

    100% {
    background: linear-gradient(90deg,#eccc68,#fc00ff);
    font-size: 55px;
    font-weight: 700;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: mymove 5s infinite linear;
    }
  }


.mainbody{
    overflow: hidden;
    position: relative;

}
.rainsb{
display: flex;
list-style: none;
}


 .rainsb .line1{
    position: absolute;
 width: 3px;
 height: 200px;
 margin-left: 50px;
 background: #fff;
 animation: rain 5s linear infinite;
 border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    opacity: 0;

}
 .rainsb .line2{
    position: absolute;
 width: 3px;
 height: 200px;
 margin-left: 5px;
 background: #fff;
 animation: rain 5s linear infinite;
 border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    animation-delay: 2s;
    opacity: 0;

}
 .rainsb .line3{
    position: absolute;
 width: 3px;
 height: 200px;
 margin-left: 20px;
 background: #fff;
 animation: rain 5s linear infinite;
 border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    animation-delay: 5s;
    opacity: 0;

}
 .rainsb .line4{
    position: absolute;
 width: 3px;
 height: 200px;
 margin-left: 30%;
 background: #fff;
 animation: rain 5s linear infinite;
 border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    animation-delay: 10s;
    opacity: 0;

}


@keyframes rain {
    0%
    {
        background: linear-gradient(transparent,rgb(208, 246, 246));
        opacity: 0.5;
        margin-top: 0px;
    }
    100%
    {
        background: linear-gradient(transparent,rgb(208, 246, 246));
        opacity: 0.4;
        margin-top: 100%;
    }
} 

.roundcircle{
    position: absolute;
    height: 300px;
    width: 300px;
    border-radius: 50%;
    border: 2px solid #5f5f5f;
    margin-left: -10%;
    margin-top: -10%;
    animation-name: circlemo;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    transition: all .3s;
}

@keyframes circlemo {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(-30px);
    }
    100% {
        transform: translateX(0px);
    }
}

.roundball{
    position: absolute;
    height: 20px;
    width: 20px;
    margin: 30px;
    margin-top: 30px;
    border-radius: 50%;
    background-color: #ffffff;
    animation-name: cirball;
    animation-duration: 7s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    transition: all .5s;
}
@keyframes cirball {
    0%   {top: 0px; left: 0px;}
    25%  {top: 0px; left: 100px;}
    50%  {top: 100px; left: 100px;}
    75%  {top: 100px; left: 0px;}
    100% {top: 0px; left: 0px;}
  }
.roundbal2{
    position: absolute;
    height: 10px;
    width: 10px;
    margin-top: 50px;
    margin-left: 30%;
    border-radius: 50%;
    background-color: #ffffff;
    animation-name: ball2;
    animation-duration: 9s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    transition: all .8s;
}
@keyframes ball2 {
    0%   {top: 0px; right: 0px;}
    25%  {top: 0px; right: 100px;}
    50%  {top: 100px; right: 100px;}
    75%  {top: 100px; right: 0px;}
    100% {top: 0px; right: 0px;}
  }
.roundb3{
    position: absolute;
    height: 7px;
    width: 7px;
    margin-top: 100px;
    margin-left: 10%;
    border-radius: 50%;
    background-color: #ffffff;
    animation-name: bt3;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    transition: all .8s;
}

@keyframes bt3 {
    from {top: 0px;}
    50%  {top: 100px !important;} /* ignored */
    to   {top: 200px;}
  }

  .roundba3{
    position: absolute;
    height: 5px;
    width: 5px;
    margin-top: 150px;
    margin-left: 30%;
    border-radius: 50%;
    background-color: #ffffff;
    animation-name: cirll3;
    animation-duration: 12s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    transition: all .5s;
  }
  @keyframes cirll3 {
    0%   {top: 0px; left: 0px;}
    25%  {top: 0px; left: 100px;}
    50%  {top: 100px; left: 100px;}
    75%  {top: 100px; left: 0px;}
    100% {top: 0px; left: 0px;}
  }
  .roundb4{
    position: absolute;
    height: 15px;
    width: 15px;
    margin-top: 100px;
    margin-left: 50%;
    border-radius: 50%;
    background-color: #ffffff;
    animation-name: cirll3;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    transition: all .5s;
  }
  @keyframes cirll3 {
    0%   {top: 0px; left: 0px;}
    25%  {top: 0px; left: 100px;}
    50%  {top: 100px; left: 100px;}
    75%  {top: 100px; left: 0px;}
    100% {top: 0px; left: 0px;}
  }


.broucher{
    position: fixed;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.iconBroucher{
    color: #ffffff;
    background: linear-gradient(90deg,#3dc1d3  0%, #33d9b2 100%);
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    display: block;
    padding: 10px;
    height: 200px;
    width: 50px;
    text-align: center;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    margin-left: -43px;
    writing-mode: vertical-rl; 
}

.icnbro{
    margin-left: 10px;
    font-size: 40px;
    width: 30px;
    height: 30px;
    margin: 5px;
    color: #ffffff;
}  