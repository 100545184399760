.carrercard{
    height: 80% ;
    width: 40% ;
    margin: 20px ;
   
}
@media only screen and (max-width: 600px) {
    .carrercard {
        height: 60% ;
        width:  80%;
        margin: 20px ;
    }
  }