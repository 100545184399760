
.serviceCard{
  
    width: 80%;
    padding: 30px 10px 15px 10px;
    /* background-color: blue; */
}
.serviceCard:hover{
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 2s;
}

.serviceicon{
    height: 80px;
    width: 80px;
    border-radius: 50%;
    border: 1px solid rgb(252, 252, 252);
  
}
.servicescard{
    height: 300px;
    margin: 20px;
    background-image: url(../Images/bg.jpg);
    background-size: cover;
    /* padding: 10rem 0 0; */
    padding: 5px;
    max-width: 35ch;
    overflow: hidden;
    border-radius: 1rem;
    transition: transform 700ms ease;

}
.servicescard:hover{
    transform: scale(1.05);
  
}
.textservice{
    padding: 1.5rem;
    background: linear-gradient(hsl(0 0% 0%/0),hsl(20 0% 0%/0.3) 20%,hsl(0 0% 0%/1));
    transform:  translateY(70%);
    transition: transform 700ms ease;

}

.textservice > *:not(.serviceName){
    opacity: 0;
    transition: opacity 700ms ease;
    
}
.servicescard:hover .textservice > *{
  opacity: 1;
}
.servicescard:hover .textservice{
    transform:  translateY(0%);
}
.serviceName{
    position: relative;
    color: #ffffff;
    font-size: 18px;
}
.serviceName::after{
    content: '';
    position: absolute;
    height: 3px;
    font-size: 12px;
    width: calc(100% + 1.5rem);
    left: calc(1.5rem * -1);
    bottom: 0;
    font-weight: 800;
    background-color: #0d7c3b;
    transform: scaleX(0);
    transform-origin: left ;

    transition: transform 700ms ease;
}

.servicescard:hover .serviceName::after{
 transform: scaleX(1);
}


.btnservice{
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    color: #000;
    background-color: #0d7c3b;
    padding: 5px;
    border-radius: 0.5rem;
    border: none;
    font-size: 13px;
}
.btnservice:hover,
.btnservice:focus{
    color: #000;
    background-color: #ffffff;
}

.iconservice{
    height: 80px;
    width: 80px;
    margin-top: 20px;
    border-radius: 10px;
    padding-top: 15px;
    position: relative;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
}
.iconsss{
    height: 50px;
    width: 50px;

}

.iconservice:hover .effectcard{
    opacity: 1;
}
.iconservice:hover .effectcard2{
    opacity: 1;
}
.iconservice:hover .effectcard3{
    opacity: 1;
}
.iconservice:hover .effectcard4{
    opacity: 1;
}

.iconservice:hover .effectcardno1{
    opacity: 1;
}
.iconservice:hover .effectcardno2{
    opacity: 1;
}
.iconservice:hover .effectcardno3{
    opacity: 1;
}
.iconservice:hover .effectcardno4{
    opacity: 1;
}

.iconservice:hover .effectcardno21{
    opacity: 1;
}
.iconservice:hover .effectcardno22{
    opacity: 1;
}
.iconservice:hover .effectcardno33{
    opacity: 1;
}
.iconservice:hover .effectcardno44{
    opacity: 1;
}

.iconservice:hover .effectcardno31{
    opacity: 1;
}
.iconservice:hover .effectcardno32{
    opacity: 1;
}
.iconservice:hover .effectcardno34{
    opacity: 1;
}
.iconservice:hover .effectcardno35{
    opacity: 1;
}

.iconservice:hover .effectcardno41{
    opacity: 1;
}
.iconservice:hover .effectcardno42{
    opacity: 1;
}
.iconservice:hover .effectcardno45{
    opacity: 1;
}
.iconservice:hover .effectcardno46{
    opacity: 1;
}

.iconservice:hover .effectcardno51{
    opacity: 1;
}
.iconservice:hover .effectcardno52{
    opacity: 1;
}
.iconservice:hover .effectcardno55{
    opacity: 1;
}
.iconservice:hover .effectcardno56{
    opacity: 1;
}

.iconservice:hover .effectcardno61{
    opacity: 1;
}
.iconservice:hover .effectcardno62{
    opacity: 1;
}
.iconservice:hover .effectcardno65{
    opacity: 1;
}
.iconservice:hover .effectcardno66{
    opacity: 1;
}

.iconservice:hover .effectcardno71{
    opacity: 1;
}
.iconservice:hover .effectcardno72{
    opacity: 1;
}
.iconservice:hover .effectcardno75{
    opacity: 1;
}
.iconservice:hover .effectcardno76{
    opacity: 1;
}

/* card no.1 */

.effectcard{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to right,transparent,#1779ff);
    animation: linemove 3s linear infinite;
    opacity: 0;
}
@keyframes linemove {
    0%{
        transform: translateX(-100%);
    }
    100%{
        transform: translateX(100%);
    }
}
.effectcard2{
    position: absolute;
    top: 0;
    right: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(to bottom,transparent,#1779ff);
    animation: linemove2 2s linear infinite;
    animation-delay: 1s;
    opacity: 0;
}
@keyframes linemove2 {
    0%{
        transform: translateY(-100%);
    }
    100%{
        transform: translateY(100%);
    }
}
.effectcard3{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to left,transparent,#1779ff);
    animation: linemove3 3s linear infinite;
    opacity: 0;
}
@keyframes linemove3 {
    0%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(-100%);
    }
}
.effectcard4{
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(to top,transparent,#1779ff);
    animation: linemove4 2s linear infinite;
    animation-delay: 1s;
    opacity: 0;
}
@keyframes linemove4 {
    0%{
        transform: translateY(100%);
    }
    100%{
        transform: translateY(-100%);
    }
}

/* card no.2 */


.effectcardno1{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to right,transparent,#c10505);
    animation: linemove 3s linear infinite;
    opacity: 0;
}
@keyframes linemove {
    0%{
        transform: translateX(-100%);
    }
    100%{
        transform: translateX(100%);
    }
}
.effectcardno2{
    position: absolute;
    top: 0;
    right: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(to bottom,transparent,#c10505);
    animation: linemove2 2s linear infinite;
    animation-delay: 1s;
    opacity: 0;
}
@keyframes linemove2 {
    0%{
        transform: translateY(-100%);
    }
    100%{
        transform: translateY(100%);
    }
}
.effectcardno3{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to left,transparent,#c10505);
    animation: linemove3 3s linear infinite;
    opacity: 0;
}
@keyframes linemove3 {
    0%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(-100%);
    }
}
.effectcardno4{
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(to top,transparent,#c10505);
    animation: linemove4 2s linear infinite;
    animation-delay: 1s;
    opacity: 0;
}
@keyframes linemove4 {
    0%{
        transform: translateY(100%);
    }
    100%{
        transform: translateY(-100%);
    }
}
/* card no.3 */


.effectcardno21{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to right,transparent,#0ccf7d);
    animation: linemove 3s linear infinite;
    opacity: 0;
}
@keyframes linemove {
    0%{
        transform: translateX(-100%);
    }
    100%{
        transform: translateX(100%);
    }
}
.effectcardno22{
    position: absolute;
    top: 0;
    right: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(to bottom,transparent,#0ccf7d);
    animation: linemove2 2s linear infinite;
    animation-delay: 1s;
    opacity: 0;
}
@keyframes linemove2 {
    0%{
        transform: translateY(-100%);
    }
    100%{
        transform: translateY(100%);
    }
}
.effectcardno33{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to left,transparent,#0ccf7d);
    animation: linemove3 3s linear infinite;
    opacity: 0;
}
@keyframes linemove3 {
    0%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(-100%);
    }
}
.effectcardno44{
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(to top,transparent,#0ccf7d);
    animation: linemove4 2s linear infinite;
    animation-delay: 1s;
    opacity: 0;
}
@keyframes linemove4 {
    0%{
        transform: translateY(100%);
    }
    100%{
        transform: translateY(-100%);
    }
}
/* card no.4 */


.effectcardno31{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to right,transparent,#0ccfb2);
    animation: linemove 3s linear infinite;
    opacity: 0;
}
@keyframes linemove {
    0%{
        transform: translateX(-100%);
    }
    100%{
        transform: translateX(100%);
    }
}
.effectcardno32{
    position: absolute;
    top: 0;
    right: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(to bottom,transparent,#0ccfb2);
    animation: linemove2 2s linear infinite;
    animation-delay: 1s;
    opacity: 0;
}
@keyframes linemove2 {
    0%{
        transform: translateY(-100%);
    }
    100%{
        transform: translateY(100%);
    }
}
.effectcardno34{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to left,transparent,#0ccfb2);
    animation: linemove3 3s linear infinite;
    opacity: 0;
}
@keyframes linemove3 {
    0%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(-100%);
    }
}
.effectcardno35{
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(to top,transparent,#0ccfb2);
    animation: linemove4 2s linear infinite;
    animation-delay: 1s;
    opacity: 0;
}
@keyframes linemove4 {
    0%{
        transform: translateY(100%);
    }
    100%{
        transform: translateY(-100%);
    }
}

/* card no.5 */


.effectcardno41{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to right,transparent,#d7940d);
    animation: linemove 3s linear infinite;
    opacity: 0;
}
@keyframes linemove {
    0%{
        transform: translateX(-100%);
    }
    100%{
        transform: translateX(100%);
    }
}
.effectcardno42{
    position: absolute;
    top: 0;
    right: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(to bottom,transparent,#d7940d);
    animation: linemove2 2s linear infinite;
    animation-delay: 1s;
    opacity: 0;
}
@keyframes linemove2 {
    0%{
        transform: translateY(-100%);
    }
    100%{
        transform: translateY(100%);
    }
}
.effectcardno45{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to left,transparent,#d7940d);
    animation: linemove3 3s linear infinite;
    opacity: 0;
}
@keyframes linemove3 {
    0%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(-100%);
    }
}
.effectcardno46{
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(to top,transparent,#d7940d);
    animation: linemove4 2s linear infinite;
    animation-delay: 1s;
    opacity: 0;
}
@keyframes linemove4 {
    0%{
        transform: translateY(100%);
    }
    100%{
        transform: translateY(-100%);
    }
}

/* card no.6 */


.effectcardno51{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to right,transparent,#880d77);
    animation: linemove 3s linear infinite;
    opacity: 0;
}
@keyframes linemove {
    0%{
        transform: translateX(-100%);
    }
    100%{
        transform: translateX(100%);
    }
}
.effectcardno52{
    position: absolute;
    top: 0;
    right: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(to bottom,transparent,#880d77);
    animation: linemove2 2s linear infinite;
    animation-delay: 1s;
    opacity: 0;
}
@keyframes linemove2 {
    0%{
        transform: translateY(-100%);
    }
    100%{
        transform: translateY(100%);
    }
}
.effectcardno55{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to left,transparent,#880d77);
    animation: linemove3 3s linear infinite;
    opacity: 0;
}
@keyframes linemove3 {
    0%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(-100%);
    }
}
.effectcardno56{
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(to top,transparent,#880d77);
    animation: linemove4 2s linear infinite;
    animation-delay: 1s;
    opacity: 0;
}
@keyframes linemove4 {
    0%{
        transform: translateY(100%);
    }
    100%{
        transform: translateY(-100%);
    }
}

/* card no.7 */


.effectcardno61{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to right,transparent,#a51053);
    animation: linemove 3s linear infinite;
    opacity: 0;
}
@keyframes linemove {
    0%{
        transform: translateX(-100%);
    }
    100%{
        transform: translateX(100%);
    }
}
.effectcardno62{
    position: absolute;
    top: 0;
    right: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(to bottom,transparent,#a51053);
    animation: linemove2 2s linear infinite;
    animation-delay: 1s;
    opacity: 0;
}
@keyframes linemove2 {
    0%{
        transform: translateY(-100%);
    }
    100%{
        transform: translateY(100%);
    }
}
.effectcardno65{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to left,transparent,#a51053);
    animation: linemove3 3s linear infinite;
    opacity: 0;
}
@keyframes linemove3 {
    0%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(-100%);
    }
}
.effectcardno66{
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(to top,transparent,#a51053);
    animation: linemove4 2s linear infinite;
    animation-delay: 1s;
    opacity: 0;
}
@keyframes linemove4 {
    0%{
        transform: translateY(100%);
    }
    100%{
        transform: translateY(-100%);
    }
}

/* card no.8 */


.effectcardno71{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to right,transparent,#9d0fa7);
    animation: linemove 3s linear infinite;
    opacity: 0;
}
@keyframes linemove {
    0%{
        transform: translateX(-100%);
    }
    100%{
        transform: translateX(100%);
    }
}
.effectcardno72{
    position: absolute;
    top: 0;
    right: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(to bottom,transparent,#9d0fa7);
    animation: linemove2 2s linear infinite;
    animation-delay: 1s;
    opacity: 0;
}
@keyframes linemove2 {
    0%{
        transform: translateY(-100%);
    }
    100%{
        transform: translateY(100%);
    }
}
.effectcardno75{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(to left,transparent,#9d0fa7);
    animation: linemove3 3s linear infinite;
    opacity: 0;
}
@keyframes linemove3 {
    0%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(-100%);
    }
}
.effectcardno76{
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(to top,transparent,#9d0fa7);
    animation: linemove4 2s linear infinite;
    animation-delay: 1s;
    opacity: 0;
}
@keyframes linemove4 {
    0%{
        transform: translateY(100%);
    }
    100%{
        transform: translateY(-100%);
    }
}