@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

h1{
    font-family: 'DM Sans', sans-serif;
}
h2{
    font-family: 'DM Sans', sans-serif;
}
h3{
    font-family: 'DM Sans', sans-serif;
}
h4{
    font-family: 'DM Sans', sans-serif;
}
h5{
    font-family: 'DM Sans', sans-serif;
}
h6{
    font-family: 'DM Sans', sans-serif;
}
Link{
    font-family: 'DM Sans', sans-serif;
}

p{
    font-family: 'DM Sans', sans-serif;
}
Button{
    font-family: 'DM Sans', sans-serif;
}

.titleNavbar{
    font-family: 'DM Sans', sans-serif;
    color:#ffffff;
    font-weight: bold;
    font-size: 13PX;
    text-decoration: none;
    margin: 8px;
}
.titleNavbar:hover{
    color: rgb(242, 156, 81);
}