.industrybg {
    height: 330px;
    width: 330px;
    border-radius: 50%;
    background-color: #c24813;
    filter: blur(70px);
    position: absolute;
    margin-top: 65px;
    margin-left: 30px;
}

.industrytxt {
    position: relative;
}