.processcard{
        /* box-shadow: 5px 5px 5px 5px #050505; */
        margin: 20px;
        padding: 20px;
        display: flex;
        position: relative;
        width: 80%;
        overflow: hidden;
        background: linear-gradient(to bottom,rgba(199,0,57,.01)0,rgba(199,0,57,.25)25%,rgba(199,0,57,.50)50%,rgba(199,0,57,.75)75%,rgba(199,0,57,.95)100%);
        border-radius: 20px;
}

    .processcard:hover::before{
        content: '';
        position: absolute;
        width:100%;
        height: 50%;
       background: #777979;
        animation: animate 3s linear infinite;
    }
    
    .processcard::after{
        content: '';
        position: absolute;
        inset: 4px;
        border-radius: 20px;
        background-color: rgb(230, 228, 228);
    }
    @keyframes animate {
        0%
        {
         transform: rotate(10deg);
        }
        100%
        {
         transform: rotate(350deg);
        }
    }
    .divCard{
        position: relative;
        z-index: 10;
        border-radius: 20px;
    }
    .textareas{
        display: flex;
        position: relative;
        z-index: 10;
    }