.aboutbg{
    height: 200px;
    width: 200px;
    border-radius: 50%;
    background-color: #c24813;
    filter: blur(40px);
    position: absolute;
    margin-top: 0%;
    margin-left: -100px;
}
.aboutbg2{
    height: 200px;
    width: 200px;
    border-radius: 50%;
    background-color: #c24813;
    filter: blur(70px);
    position: absolute;
    margin-top: 45%;
    margin-left: 50%;
}
.abouttxt{
    position: relative;
}

.imgmoment{
    animation-name: infiniteupdown;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    transition: all .5s;
}


@keyframes infiniteupdown {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}